<template lang="pug">
transition(
  enter-active-class="animated slideInLeft"
  leave-active-class="animated slideOutLeft"
)
  .vc-common-mobile-menu(v-if="mobileMenuStates['isMobileMenuActive']")
    //- Root Menu
    transition(
      enter-active-class="animated slideInLeft"
      leave-active-class="animated slideOutLeft"
    )
      .menu-wrapper.root(
        v-if="!mobileMenuStates['isCategoriesSelectorActive']"
      )
        ul.mobile-menu-items
          li.mobile-menu-item(v-for="(info, index) in navMenuList")
            a.link(
              :href="info[1]['href']"
              @click.prevent="linkClickedHandler(info)"
            )
              span.text {{ info[1]['title'] }}
              .icon(v-if="categoryMenuTypes.includes(info[0])")
                i.fa.fa-chevron-right

    //- Category list menu
    transition(
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutRight"
    )
      categoryMenu(v-if="isCategoriesSelectorActive")
</template>

<script>
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock'
import debounce from 'lodash.debounce'
import productCategoryList from './mobile-product-category-list.vue'
import categoryList from './mobile-category-list.vue'
import categoryMenu from './mobile-category-menu.vue'

export default {
  components: {
    productCategoryList,
    categoryList,
    categoryMenu
  },
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },

  computed: {
    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },

    isCategoriesSelectorActive() {
      if (this.mobileMenuStates['isCategoriesSelectorActive']) {
        return !!this.mobileMenuStates['activatedCategoryType']
      }
    },

    navMenuList() {
      return (
        this.$store.getters['siteConfigs/siteCustomDisplayConfig']
          .navbar_settings?.display_items || []
      )
    },

    categoryMenuTypes() {
      return ['product', 'sales_event', 'adults_only']
    }
  },

  watch: {
    'mobileMenuStates.isMobileMenuActive': {
      immediate: true,
      handler: function (isActive) {
        this.$nextTick(() => {
          let target = document.querySelector('.vc-common-mobile-menu')

          if (!target) return

          if (isActive) {
            document.documentElement.classList.add('no-scroll')
            return disableBodyScroll(target)
          }

          document.documentElement.classList.remove('no-scroll')
          enableBodyScroll(target)
        })
      }
    }
  },
  // created() {},
  mounted() {
    this.detectInnerHeight()
    window.addEventListener('scroll', this.detectInnerHeight, 200)
    window.addEventListener('resize', this.detectInnerHeight, 200)
  },

  beforeDestroy() {
    clearAllBodyScrollLocks()
    document.documentElement.classList.remove('no-scroll')
    window.removeEventListener('scroll', this.detectInnerHeight, 200)
    window.removeEventListener('resize', this.detectInnerHeight, 200)
  },

  methods: {
    detectInnerHeight: debounce(function () {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }, 200),

    linkClickedHandler(info) {
      let key = info[0]
      let value = info[1]
      let href = value['href']

      if (this.categoryMenuTypes.includes(key)) {
        this.categoriesSelectorTriggerClickedHandler(key)
      } else {
        this.$store.dispatch(
          'toggleMobileMenu',
          Object.assign(this.mobileMenuStates, { isMobileMenuActive: false })
        )

        if (value['is_target_blank']) return window.open(href)
        this.$nextTick(() => {
          href === '/#contact'
            ? (window.location = href)
            : Turbolinks.visit(href)
        })
      }
    },

    categoriesSelectorTriggerClickedHandler(categoryType) {
      this.$store.dispatch(
        'toggleMobileMenu',
        Object.assign(this.mobileMenuStates, {
          isCategoriesSelectorActive: true,
          activatedCategoryType: categoryType
        })
      )
    }
  }
}
</script>
